import { isRtl } from '@app/utils/helpers';
const mainTheme = {
    fontFamilyRegular: (isRtl() ? 'Noto Kufi Regular' : 'Roboto Regular') + ", Arial, Helvetica, sans-serif",
    fontFamily: (isRtl() ? 'Noto Kufi Regular' : 'Roboto') + ", Arial, Helvetica, sans-serif",
    fontFamilyMedium: (isRtl() ? 'Noto Kufi Bold' : 'Roboto Medium') + ", Arial, Helvetica, sans-serif",
    fontFamilyLight: (isRtl() ? 'Noto Kufi Regular' : 'Roboto Light') + ", Arial, Helvetica, sans-serif",
    fontFamilyThin: (isRtl() ? 'Noto Kufi Regular' : 'Roboto Thin') + ", Arial, Helvetica, sans-serif",
    backgroundColor: 'white',
    lightGray: '#F6F6F6',
    lightGray2: '#E1E1E1',
    lightGray3: '#D8D5D5',
    lightGray4: '#707070',
    lightGray5: '#d5d5d7',
    lightGray6: '#979797',
    lightGray7: '#9F9F9F',
    lightGray8: '#F5F5F5',
    lightGray9: '#F0F0F0',
    lightGray10: '#E2DFDF',
    lightGray11: '#F2F2F2',
    lightGray12: '#FBFBFB',
    blue: '#2F7BF7',
    darkBlue: '#377093',
    red: '#EF4317',
    red2: '#E90500',
    black: '#232323',
    lightBlack: '#646464',
    black_o168: '#000000a7',
    white: '#fff',
    gold: '#f89932',
    gold2: '#FA9A1D',
    lightGold: '#fef5ec',
    darkGreen: '#005342',
    darkGreen2: '#003C30',
    lightGreen2: '#daddd1',
    primaryColor: '#005342',
    KSAFoundingDay: '#16a947',
    primaryVariantColor: '#003a2f',
    secondaryColor: '#f89932',
    lightGreen: '#d4e7b7',
    doubleDarkGreen: '#003a2f',
    gray: '#535353',
    gray2: '#797979',
    gray3: '#DADED1',
    gray4: '#676767',
    gray5: '#3c3c3c',
    gray6: '#e4e4e4',
    gray7: '#474747',
    grayOpacity5: 'rgba(250,154,29,0.5)',
    lightBlack2: '#4f4f4f',
    lightBlack3: 'rgba(28,28,28,0.5)',
    success: '#119960',
    valentine: '#aa041e'
};
const darkTheme = {
    ...mainTheme,
    ...{
        backgroundColor: 'black'
    }
};
export { darkTheme, mainTheme };
