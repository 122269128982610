import { clearGtmEventMapperCookie, getGtmEventMapperCookie, checkForUpdates } from '.';
import { GTM_EVENT } from '../defines';
import { setCookieValue } from './storage';
// import mixpanel from 'mixpanel-browser';
/**
 * https://www.keycloak.org/docs/latest/securing_apps/index.html#javascript-adapter-reference
 * */
const noops = {
    string: () => 'noop',
    boolean: () => true
};
const myPromise = {
    success: _cb => {
        return myPromise;
    },
    error: _cb => {
        return myPromise;
    }
};
// this is a fake Keycloak instance we use to initialize Keycloak on the server.
// This gets over-written as soon as Keycloak is initialized on the client.
const keycloakDefault = {
    login: _options => {
        return myPromise;
    },
    logout: _options => {
        return myPromise;
    },
    register: _options => {
        return myPromise;
    },
    accountManagement: () => {
        return myPromise;
    },
    init: _options => {
        return myPromise;
    },
    createLoginUrl: noops.string,
    createLogoutUrl: noops.string,
    createAccountUrl: noops.string,
    createRegisterUrl: noops.string,
    isTokenExpired: noops.boolean,
    updateToken: _options => myPromise,
    clearToken: noops.string,
    hasRealmRole: noops.boolean,
    hasResourceRole: noops.boolean,
    loadUserProfile: () => myPromise,
    loadUserInfo: () => myPromise,
    authenticated: false
};
export const Keycloak = typeof window == 'undefined' ? null : require('keycloak-js');
import { getUserLanguage, getWebsiteDirection, setUserLanguage, setWebsiteDirection, storeUserLocally, getUserMarket } from './userHelpers';
export const initOptions = {
    realm: process.env.KEYCLOAK_REALM || 'floranow-dev',
    clientId: process.env.KEYCLOAK_CLIENTID || 'marketplace-fe',
    checkLoginIframe: false,
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: typeof window == 'undefined' ? '' : window.location.origin + '/silent-check-sso.html',
    url: process.env.KEYCLOAK_AUTH || 'https://keychain.floranow.com/auth/'
};
const keycloak = typeof window == 'undefined' ? keycloakDefault : new Keycloak(initOptions);
export const refreshUser = () => {
    var _storeUserLocally;
    const { token, refreshToken } = keycloak;
    const lng = getUserLanguage() || 'en';
    const websiteDirection = getWebsiteDirection() || 'ltr';
    const shouldRedirect = localStorage.getItem('redirect_login');
    (_storeUserLocally = storeUserLocally(token, refreshToken)) === null || _storeUserLocally === void 0 ? void 0 : _storeUserLocally.then(user => {
        var _user$is_master, _window, _keycloak$tokenParsed2, _user$country2, _user$purchaseLimit2, _user$purchaseLimitSp2, _user$is_master3, _user$is_blocked2, _user$exceed_limit2, _user$user_category, _user$debtor_number, _user$is_internal, _user$creditLimit, _user$region, _user$is_bulk_user, _user$user_type;
        setCookieValue('user', JSON.stringify(user));
        setCookieValue('is_master', (_user$is_master = user === null || user === void 0 ? void 0 : user.is_master) !== null && _user$is_master !== void 0 ? _user$is_master : false);
        setUserLanguage(lng);
        setWebsiteDirection(websiteDirection);
        window.dataLayer = ((_window = window) === null || _window === void 0 ? void 0 : _window.dataLayer) || [];
        const loginGtmEvent = getGtmEventMapperCookie(GTM_EVENT.LOGIN);
        if (loginGtmEvent) {
            var _window2, _keycloak$tokenParsed, _user$country, _user$purchaseLimit, _user$purchaseLimitSp, _user$is_master2, _user$is_blocked, _user$exceed_limit;
            (_window2 = window) === null || _window2 === void 0 ? void 0 : _window2.dataLayer.push({
                event: GTM_EVENT.LOGIN,
                userId: keycloak === null || keycloak === void 0 ? void 0 : (_keycloak$tokenParsed = keycloak.tokenParsed) === null || _keycloak$tokenParsed === void 0 ? void 0 : _keycloak$tokenParsed.floranow_user_id,
                clientLoginState: 'logged-in',
                country: user === null || user === void 0 ? void 0 : (_user$country = user.country) === null || _user$country === void 0 ? void 0 : _user$country.toString(),
                purchaseLimit: user === null || user === void 0 ? void 0 : (_user$purchaseLimit = user.purchaseLimit) === null || _user$purchaseLimit === void 0 ? void 0 : _user$purchaseLimit.toString(),
                purchaseLimitSpend: user === null || user === void 0 ? void 0 : (_user$purchaseLimitSp = user.purchaseLimitSpend) === null || _user$purchaseLimitSp === void 0 ? void 0 : _user$purchaseLimitSp.toString(),
                is_master: user === null || user === void 0 ? void 0 : (_user$is_master2 = user.is_master) === null || _user$is_master2 === void 0 ? void 0 : _user$is_master2.toString(),
                is_blocked: user === null || user === void 0 ? void 0 : (_user$is_blocked = user.is_blocked) === null || _user$is_blocked === void 0 ? void 0 : _user$is_blocked.toString(),
                exceed_limit: user === null || user === void 0 ? void 0 : (_user$exceed_limit = user.exceed_limit) === null || _user$exceed_limit === void 0 ? void 0 : _user$exceed_limit.toString()
            });
            clearGtmEventMapperCookie(GTM_EVENT.LOGIN);
        }
        window.dataLayer.push({
            event: GTM_EVENT.USER_DATA,
            userId: keycloak === null || keycloak === void 0 ? void 0 : (_keycloak$tokenParsed2 = keycloak.tokenParsed) === null || _keycloak$tokenParsed2 === void 0 ? void 0 : _keycloak$tokenParsed2.floranow_user_id,
            clientLoginState: 'logged-in',
            country: user === null || user === void 0 ? void 0 : (_user$country2 = user.country) === null || _user$country2 === void 0 ? void 0 : _user$country2.toString(),
            purchaseLimit: user === null || user === void 0 ? void 0 : (_user$purchaseLimit2 = user.purchaseLimit) === null || _user$purchaseLimit2 === void 0 ? void 0 : _user$purchaseLimit2.toString(),
            purchaseLimitSpend: user === null || user === void 0 ? void 0 : (_user$purchaseLimitSp2 = user.purchaseLimitSpend) === null || _user$purchaseLimitSp2 === void 0 ? void 0 : _user$purchaseLimitSp2.toString(),
            is_master: user === null || user === void 0 ? void 0 : (_user$is_master3 = user.is_master) === null || _user$is_master3 === void 0 ? void 0 : _user$is_master3.toString(),
            is_blocked: user === null || user === void 0 ? void 0 : (_user$is_blocked2 = user.is_blocked) === null || _user$is_blocked2 === void 0 ? void 0 : _user$is_blocked2.toString(),
            exceed_limit: user === null || user === void 0 ? void 0 : (_user$exceed_limit2 = user.exceed_limit) === null || _user$exceed_limit2 === void 0 ? void 0 : _user$exceed_limit2.toString(),
            user_category: user === null || user === void 0 ? void 0 : (_user$user_category = user.user_category) === null || _user$user_category === void 0 ? void 0 : _user$user_category.toString(),
            debtor_number: user === null || user === void 0 ? void 0 : (_user$debtor_number = user.debtor_number) === null || _user$debtor_number === void 0 ? void 0 : _user$debtor_number.toString(),
            is_internal: user === null || user === void 0 ? void 0 : (_user$is_internal = user.is_internal) === null || _user$is_internal === void 0 ? void 0 : _user$is_internal.toString(),
            creditLimit: user === null || user === void 0 ? void 0 : (_user$creditLimit = user.creditLimit) === null || _user$creditLimit === void 0 ? void 0 : _user$creditLimit.toString(),
            user_region: user === null || user === void 0 ? void 0 : (_user$region = user.region) === null || _user$region === void 0 ? void 0 : _user$region.toString(),
            is_bulk_user: user === null || user === void 0 ? void 0 : (_user$is_bulk_user = user.is_bulk_user) === null || _user$is_bulk_user === void 0 ? void 0 : _user$is_bulk_user.toString(),
            user_type: user === null || user === void 0 ? void 0 : (_user$user_type = user.user_type) === null || _user$user_type === void 0 ? void 0 : _user$user_type.toString()
        });
        if (shouldRedirect == 'true') {
            // mixpanel.register({
            //   user: JSON.stringify(user),
            //   lng: lng,
            //   websiteDirection: websiteDirection,
            // });
            // mixpanel.identify(user.eMailAddress);
            // mixpanel.track('Sign in');
            localStorage.setItem('redirect_login', 'false');
            const userMarket = getUserMarket(user.country, user.user_type == 'bulk');
            const currentHostname = 'https://' + window.location.hostname;
            let url = new URL(currentHostname);
            let isContainWWW = url.host.split('.')[0] == 'www';
            if (currentHostname != userMarket && !isContainWWW) {
                setTimeout(() => {
                    window.location.href = userMarket;
                }, 500);
            }
        }
    });
};
export const onEvent = (event, error) => {
    switch (event) {
        case 'onReady':
            break;
        // case 'onInitError':
        //   console.log('onInitError');
        //   break;
        case 'onAuthSuccess':
            localStorage.removeItem('pages');
            localStorage.removeItem('pages-creation-date');
            refreshUser();
            checkForUpdates();
            break;
        case 'onAuthError':
            break;
        case 'onAuthRefreshSuccess':
            break;
        case 'onAuthRefreshError':
            // keycloak.logout();
            break;
        case 'onTokenExpired':
            // keycloak.updateToken();
            break;
        case 'onAuthLogout':
            break;
    }
};
if (typeof window != 'undefined')
    window['keycloak'] = keycloak; // for debuggings
export default keycloak;
