/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import FnThemeProvider from './src/layouts/FnThemeProvider';
import React from 'react';
import axios from 'axios';
import {
  getUserLanguage,
  setUserLanguage,
  getWebsiteDirection,
  setWebsiteDirection,
} from './src/utils/helpers/userHelpers';
import {getCountryCode, checkForUpdates} from './src/utils/helpers';
import {navigate} from 'gatsby';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import keycloak, {initOptions, onEvent} from './src/utils/helpers/keycloak';
// import ZohoSalesIQ from './src/components/ZohoSalesIQ';
import Sematext from './src/components/sematext';
import store from '@app/stateManagment/reduxStore';
import {Provider} from 'react-redux';
import {GrowthBook, GrowthBookProvider} from '@growthbook/growthbook-react';
import {getCookieValue} from '@utils/helpers/storage';
import {GTM_EVENT} from '@app/utils/defines';
import './src/assets/styles/global.css'

export const growthbook = new GrowthBook({});

export const onClientEntry = () => {
  let user = getCookieValue('user');
  user = JSON.parse(user != '' ? user : '{}');
  window.dataLayer = window.dataLayer || [];
  let userId = undefined;
  if (keycloak && keycloak.authenticated) {
    userId =
      keycloak &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed?.floranow_user_id;
  }

  if( !keycloak || !keycloak.authenticated) {
    window.dataLayer.push({
      event: GTM_EVENT.USER_DATA,
      userId: userId,
      clientLoginState: 'logged-out',
      country: user?.country?.toString(),
      purchaseLimit: user?.purchaseLimit?.toString(),
      purchaseLimitSpend: user?.purchaseLimitSpend?.toString(),
      is_master: user?.is_master?.toString(),
      is_blocked: user?.is_blocked?.toString(),
      exceed_limit: user?.exceed_limit?.toString(),
      user_category: user?.user_category?.toString(),
      debtor_number: user?.debtor_number?.toString(),
      is_internal: user?.is_internal?.toString(),
      creditLimit: user?.creditLimit?.toString(),
    });
  }
};

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

axios.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    if (typeof window == 'undefined') {
      return;
    }

    if (error.response?.status === 503) {
      const {end_time, message} = error.response.data;
      localStorage.setItem('fn_maintenance_time', end_time);
      localStorage.setItem('fn_maintenance_msg', message);
      if (!location.pathname.includes('/503')) {
        navigate('/503');
      }
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(async config => {
  checkForUpdates();

  if (typeof window == 'undefined') {
    return;
  }
  const accessToken = keycloak?.token;

  const code = getCountryCode();
  const countryConfig = {};
  if (code == 'trade') {
    countryConfig.bulk = true;
    countryConfig.country = null;
  } else {
    countryConfig.country = code;
  }

  config.params = {
    ...config.params,
    ...countryConfig,
    language: getUserLanguage() ?? 'en',
  };

  config.headers = {
    ...config.headers,
    'Cache-Control': 'no-cache',
  };
  if (accessToken) {
    config.headers.authorization = `Bearer ${accessToken}`;
  }

  if (keycloak.tokenParsed?.floranow_user_id) {
    config.headers['c-key'] = keycloak.tokenParsed?.floranow_user_id;
  }
  return config;
});

const initApp = () => {
  if (!getUserLanguage()) {
    setUserLanguage('en');
  }

  if (!getWebsiteDirection()) {
    setWebsiteDirection('ltr');
  }
};

initApp();
// mixpanel.init('e792cff337ac0f0baa181a5ac2b88a62');
// mixpanel.set_config({ secure_cookie: true });

export const wrapRootElement = ({element}) => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      onEvent={onEvent}
    >
      <GrowthBookProvider growthbook={growthbook}>
        <FnThemeProvider>
          <Provider store={store}>
            <Sematext />
            {element}
          </Provider>
          {/* <ZohoSalesIQ></ZohoSalesIQ> */}
        </FnThemeProvider>
      </GrowthBookProvider>
    </ReactKeycloakProvider>
  );
};